@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/be-vietnam-pro-v10-latin-regular.eot');
	src: local(''),
		url('../fonts/be-vietnam-pro-v10-latin-regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/be-vietnam-pro-v10-latin-regular.woff2') format('woff2'),
		url('../fonts/be-vietnam-pro-v10-latin-regular.woff') format('woff'),
		url('../fonts/be-vietnam-pro-v10-latin-regular.ttf') format('truetype'),
		url('../fonts/be-vietnam-pro-v10-latin-regular.svg#BeVietnamPro') format('svg');
}

@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: italic;
	font-weight: 400;
	src: url('../fonts/be-vietnam-pro-v10-latin-italic.eot');
	src: local(''),
		url('../fonts/be-vietnam-pro-v10-latin-italic.eot?#iefix') format('embedded-opentype'),
		url('../fonts/be-vietnam-pro-v10-latin-italic.woff2') format('woff2'),
		url('../fonts/be-vietnam-pro-v10-latin-italic.woff') format('woff'),
		url('../fonts/be-vietnam-pro-v10-latin-italic.ttf') format('truetype'),
		url('../fonts/be-vietnam-pro-v10-latin-italic.svg#BeVietnamPro') format('svg');
}

@font-face {
	font-family: 'Be Vietnam Pro';
	font-style: normal;
	font-weight: 600;
	src: url('../fonts/be-vietnam-pro-v10-latin-600.eot');
	src: local(''),
		url('../fonts/be-vietnam-pro-v10-latin-600.eot?#iefix') format('embedded-opentype'),
		url('../fonts/be-vietnam-pro-v10-latin-600.woff2') format('woff2'),
		url('../fonts/be-vietnam-pro-v10-latin-600.woff') format('woff'),
		url('../fonts/be-vietnam-pro-v10-latin-600.ttf') format('truetype'),
		url('../fonts/be-vietnam-pro-v10-latin-600.svg#BeVietnamPro') format('svg');
}

* {
	font-family: 'Be Vietnam Pro';
	padding: 0;
	margin: 0;
}

body {
	width: 100vw;
	height: 100vh;
	font-family: var(--cy-fontFamily-default);
}

#root {
	height: 100%;
}
